import {
    getModule,
    Module,
    MutationAction,
    VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { CommonResponse } from "@/api/response";
import { RegisterRequest } from "@/api/line-miniapp/request";
import * as LineMiniappAPI from "@/api/line-miniapp";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "line-miniapp/register";

/**
 * LINEミニアプリ設定更新API（/regest-line-setting）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Register extends VuexModule {
    // state
    commonResponse: CommonResponse = {} as CommonResponse;

    // getters
    get isSuccess() {
        return isSuccess(this.commonResponse);
    }

    get getMessage() {
        return this.commonResponse.message;
    }

    // MutationActions
    @MutationAction
    async register(registerRequest: RegisterRequest) {
        const commonResponse = await LineMiniappAPI.register(registerRequest);
        return {
            commonResponse
        };
    }

    @MutationAction
    async clear() {
        return {
            commonResponse: {} as CommonResponse
        };
    }
}

export default getModule(Register);
