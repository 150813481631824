import { Component, Mixins, Prop } from "vue-property-decorator";
import Draggable from "vuedraggable";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
// @ts-ignore
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { RegisterRequest } from "@/api/line-miniapp/request";
import LineSettingGetAPI from "@/store/line-miniapp/get";
import LineSettingRegisterAPI from "@/store/line-miniapp/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";
import AppStatusModify from "@/store/app-creation-status-shop/modify";
import { SettingRequest } from "@/api/line-miniapp/request";
import Admin from "@/store/admin/admin";

@Component({
    components: {
        UlContentHeader,
        UlBreadcrumbs,
        UIFormRow,
        Draggable
    }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
    @Prop({type: String, required: true})
    shopId!: string;

    // タイトル
    headingMain = "LINEミニアプリ";
    headingSub = "LINE mini app";

    // パンくず
    breadCrumbs = [
        {text: "LINEミニアプリ", disabled: true},
        {
            text: "店舗一覧",
            disabled: false,
            to: {name: "line-miniapp"}
        },
        {text: "設定", disabled: true}
    ];

    // 予約時間（時）のselect
    hours = [...Array(24).keys()].map(n => {
        const val = ("00" + n).slice(-2);
        return { text: val, value: val };
    });

    // 予約時間（分）のselect
    minutes = [...Array(6).keys()].map(n => {
        const val = ("00" + n * 10).slice(-2);
        return { text: val, value: val };
    });

    // ------------
    // 変動値
    // ------------

    // ローディングステータス
    isLoading = false;

    // 入力データ
    inputData = {
        id: null as null | number,
        shopId: Number(this.shopId),
        lineType: [] as string[],
        lineAuthCoupon: "0",
        lineAuthTicket: "0",
        liffUrl: null as string | null,
        writeFlg: null as null | number,
        channelId: null as string | null,
        channelSecret: null as string | null,
        channelLiffId: null as string | null,
        stampSendDate: null as string | null,
        couponSendDate: null as string | null,
        completeSendDate: null as string | null,
        stampSendTime:  null as string | null,
        couponSendTime:  null as string | null,
        completeSendTime:  null as string | null,
        stampSendHour:  null as string | null,
        couponSendHour:  null as string | null,
        completeSendHour:  null as string | null,
        stampSendMinute:  null as string | null,
        couponSendMinute:  null as string | null,
        completeSendMinute:  null as string | null,
    }

    get isAdmin() {
        return Admin.isAdmin;
    }

    get stampSendHour() {
        let hour = "";
        if (this.inputData.stampSendTime) {
            const val = this.inputData.stampSendTime.split(":");
            (val.length >= 1) ? hour = val[0] : hour = "00";
        }
        if(hour !== "undefined") {
            this.inputData.stampSendHour = hour;
        }
        return hour;
    }

    get stampSendMinute() {
        let minute = "";
        if (this.inputData.stampSendTime) {
            const val = this.inputData.stampSendTime.split(":");
            (val.length >= 2) ? minute = val[1] : minute = "00";
        }
        if(minute !== "undefined") {
            this.inputData.stampSendMinute = minute;
        }
        return minute;
    }

    set stampSendHour(hour) {
        if (this.inputData.stampSendTime) {
            const val = this.inputData.stampSendTime.split(":");
            if (val.length >= 2) {
                this.inputData.stampSendTime = `${hour}:${val[1]}`;
            } else {
                this.inputData.stampSendTime = `${hour}:00`;
            }
        } else {
            this.inputData.stampSendTime = `${hour}:`;
        }
        if(hour !== "undefined") {
            this.inputData.stampSendHour = hour;
        }
    }

    set stampSendMinute(min) {
        if (this.inputData.stampSendTime) {
            const val = this.inputData.stampSendTime.split(":");
            if (val.length >= 1) {
                this.inputData.stampSendTime = `${val[0]}:${min}`;
            } else {
                this.inputData.stampSendTime = `00:${min}`;
            }
        } else {
            this.inputData.stampSendTime = `:${min}`;
        }
        if(min !== "undefined") {
            this.inputData.stampSendMinute = min;
        }
    }

    get couponSendHour() {
        let hour = "";
        if (this.inputData.couponSendTime) {
            const val = this.inputData.couponSendTime.split(":");
            (val.length >= 1) ? hour = val[0] : hour = "00";
        }
        if(hour !== "undefined") {
            this.inputData.couponSendHour = hour;
        }
        return hour;
    }

    get couponSendMinute() {
        let minute = "";
        if (this.inputData.couponSendTime) {
            const val = this.inputData.couponSendTime.split(":");
            (val.length >= 2) ? minute = val[1] : minute = "00";
        }
        if(minute !== "undefined") {
            this.inputData.couponSendMinute = minute;
        }
        return minute;
    }

    set couponSendHour(hour) {
        if (this.inputData.couponSendTime) {
            const val = this.inputData.couponSendTime.split(":");
            if (val.length >= 2) {
                this.inputData.couponSendTime = `${hour}:${val[1]}`;
            } else {
                this.inputData.couponSendTime = `${hour}:00`;
            }
        } else {
            this.inputData.couponSendTime = `${hour}:`;
        }
        if(hour !== "undefined") {
            this.inputData.couponSendHour = hour;
        }
    }

    set couponSendMinute(min) {
        if (this.inputData.couponSendTime) {
            const val = this.inputData.couponSendTime.split(":");
            if (val.length >= 1) {
                this.inputData.couponSendTime = `${val[0]}:${min}`;
            } else {
                this.inputData.couponSendTime = `00:${min}`;
            }
        } else {
            this.inputData.couponSendTime = `:${min}`;
        }
        if(min !== "undefined") {
            this.inputData.couponSendMinute = min;
        }

    }

    get completeSendHour() {
        let hour = "";
        if (this.inputData.completeSendTime) {
            const val = this.inputData.completeSendTime.split(":");
            (val.length >= 1) ? hour = val[0] : hour = "00";
        }
        if(hour !== "undefined") {
            this.inputData.completeSendHour = hour;
        }
        return hour;
    }

    get completeSendMinute() {
        let minute = "";
        if (this.inputData.completeSendTime) {
            const val = this.inputData.completeSendTime.split(":");
            (val.length >= 2) ? minute = val[1] : minute = "00";
        }
        if(minute !== "undefined") {
            this.inputData.completeSendMinute = minute;
        }
        return minute;
    }

    set completeSendHour(hour) {
        if (this.inputData.completeSendTime) {
            const val = this.inputData.completeSendTime.split(":");
            if (val.length >= 2) {
                this.inputData.completeSendTime = `${hour}:${val[1]}`;
            } else {
                this.inputData.completeSendTime = `${hour}:00`;
            }
        } else {
            this.inputData.completeSendTime = `${hour}:`;
        }
        if(hour !== "undefined") {
            this.inputData.completeSendHour = hour;
        }
    }

    set completeSendMinute(min) {
        if (this.inputData.completeSendTime) {
            const val = this.inputData.completeSendTime.split(":");
            if (val.length >= 1) {
                this.inputData.completeSendTime = `${val[0]}:${min}`;
            } else {
                this.inputData.completeSendTime = `00:${min}`;
            }
        } else {
            this.inputData.completeSendTime = `:${min}`;
        }
        if(min !== "undefined") {
            this.inputData.completeSendMinute = min;
        }
    }

    //ローディングステータス
    get loading() {
        return Loading.isLoading;
    }


    /**
     * createdライフサイクルフック
     */
    async created() {
        await this.get();
        this.syncParams();
    }

    /**
     * beforeDestroyライフサイクルフック
     */
    async beforeDestroy() {
        await LineSettingGetAPI.clear();
        await LineSettingRegisterAPI.clear();
    }

    /**
     * LINEapp情報の取得処理
     */
    async get(): Promise<boolean> {
        if (LineSettingGetAPI.isSuccess) {
            return true;
        }

        const request = this.createGetRequest();
        await LineSettingGetAPI.setting(request);
        if (!LineSettingGetAPI.isSuccess) {
            await Flash.setErrorNow({
                message: LineSettingGetAPI.getMessage,
                showReloadButton: true
            } as ErrorAlert);
        }
        return LineSettingGetAPI.isSuccess;
    }

    /**
     * 更新する
     */
    async register() {
        await Flash.clear();
        this.isLoading = true;
        await LineSettingRegisterAPI.register(this.createRegisterRequest());
        if (LineSettingRegisterAPI.isSuccess) {
            await this.redirectWithSuccessAlert(
                "保存しました。",
                "/line-miniapp"
            );
        } else {
            await Flash.setErrorNow({
                message: LineSettingRegisterAPI.getMessage,
                showReloadButton: false
            });
        }
    }

    /**
     * dataから情報取得用のリクエストを作成する
     */
    private createGetRequest() {
        return {shopId: this.shopId} as SettingRequest;
    }

    /**
     * dataから登録更新用のリクエストを作成する
     */
    private createRegisterRequest() {
        // 必須項目
        var lineType = ''
        if(this.inputData.lineType.length > 0){
            lineType = JSON.stringify(this.inputData.lineType)
        }
        const request = {
            id: this.inputData.id,
            shopId: this.inputData.shopId,
            lineType: lineType,
            lineAuthCoupon: this.inputData.lineAuthCoupon,
            lineAuthTicket: this.inputData.lineAuthTicket,
            liffUrl: this.inputData.liffUrl,
            clientId: this.inputData.channelId,
            clientSecret: this.inputData.channelSecret,
            liffId: this.inputData.channelLiffId,
            messageDateStamp: this.inputData.stampSendDate,
            messageDateCoupon: this.inputData.couponSendDate,
            messageDateTicket: this.inputData.completeSendDate,
            massageTimeStamp:  this.inputData.stampSendTime,
            massageTimeCoupon:  this.inputData.couponSendTime,
            massageTimeTicket:  this.inputData.completeSendTime,
            stampSendHour:  this.inputData.stampSendHour,
            couponSendHour:  this.inputData.couponSendHour,
            completeSendHour:  this.inputData.completeSendHour,
            stampSendMinute:  this.inputData.stampSendMinute,
            couponSendMinute:  this.inputData.couponSendMinute,
            completeSendMinute:   this.inputData.completeSendMinute,
        } as RegisterRequest;
        return request;
    }

    /**
     * APIのレスポンスをdataにsyncする
     */
    private syncParams() {
        this.inputData.id = LineSettingGetAPI.getId;
        this.inputData.shopId = LineSettingGetAPI.getShopId;
        this.inputData.lineType = LineSettingGetAPI.getLineType;
        this.inputData.lineAuthCoupon = String(LineSettingGetAPI.getLineAuthCoupon);
        this.inputData.lineAuthTicket = String(LineSettingGetAPI.getLineAuthTicket);
        this.inputData.liffUrl = LineSettingGetAPI.getLiffUrl;
        this.inputData.writeFlg = LineSettingGetAPI.getWriteFlg;

        this.inputData.channelId = String(LineSettingGetAPI.getChannelId);
        this.inputData.channelSecret = String(LineSettingGetAPI.getChannelSecret);
        this.inputData.channelLiffId = String(LineSettingGetAPI.getChannelLiffId);
        this.inputData.stampSendDate = String(LineSettingGetAPI.getStampSendDate);
        this.inputData.couponSendDate = String(LineSettingGetAPI.getCouponSendDate);
        this.inputData.completeSendDate = String(LineSettingGetAPI.getCompleteSendDate);
        this.inputData.stampSendTime = String(LineSettingGetAPI.getStampSendTime);
        this.inputData.couponSendTime = String(LineSettingGetAPI.getCouponSendTime);
        this.inputData.completeSendTime = String(LineSettingGetAPI.getCompleteSendTime);
    }
}