import service from "@/api/service";
import {SettingRequest, RegisterRequest} from "@/api/line-miniapp/request";
import {SettingResponse, GetResponse} from "@/api/line-miniapp/response";
import {CommonResponse} from "@/api/response";
import {SearchRequest} from "@/api/shop/request";
import {SearchResponse} from "@/api/shop/response";

/**
 * 店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
    const response = await service.post("/get-line");
    return response.data as GetResponse;
}

/**
 * 店舗一覧・検索APIをコールします。
 *
 * @param searchRequest 店舗検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
    const response = await service.post("/get-line-search", searchRequest);
    return response.data as SearchResponse;
}

/**
 * LINEミニアプリ設定取得APIをコールします。
 *
 * @param settingRequest LINEミニアプリ設定取得のリクエストボディ
 */
export async function setting(settingRequest: SettingRequest) {
    const response = await service.post("/get-line-setting", settingRequest);
    return response.data as SettingResponse;
}

/**
 * LINEミニアプリ設定更新APIをコールします。
 *
 * @return GetResponse
 */
export async function register(registerRequest: RegisterRequest) {
    const response = await service.post("/regest-line-setting", registerRequest);
    return response.data as CommonResponse;
}
